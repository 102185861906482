@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  *:after,
  *:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Inter', sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    background-color: #fdfdfd;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.MuiClock-squareMask {
  background-color: #d7e3f5;
}

.MuiPickersLayout-root * {
  font-family: 'Inter', sans-serif !important;
}

.MuiClock-pin,
.MuiClockPointer-root,
.MuiClockPointer-thumb {
  background-color: #3874cb !important;
}

.MuiClockPointer-thumb {
  border: 1rem solid #3874cb !important;
}

.MuiPickersToolbar-root button {
  background-color: #d7e3f5;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.MuiPickersToolbar-root button span {
  font-size: 2.5rem;
}

.MuiPickersToolbar-root button span.Mui-selected {
  color: #175a93 !important;
}

.MuiPickersLayout-actionBar button {
  text-transform: capitalize;
  color: #3874cb;
}

.MuiPaper-elevation {
  box-shadow: none !important;
}

.MuiPickersLayout-contentWrapper {
  background-color: #f3f6fc;
  font-weight: 400;
  border-radius: 6px;
}

.MuiPickersCalendarHeader-label {
  text-transform: uppercase;
  font-weight: 400;
}

.MuiPickersToolbar-content {
  display: flex !important;
  justify-content: center !important;
}
